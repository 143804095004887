import { default as driversBzebRKmqnWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/drivers.vue?macro=true";
import { default as indexGVHGTSwFxvMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/index.vue?macro=true";
import { default as index1ioKyqHhlRMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/index.vue?macro=true";
import { default as editBRTL0IlKNWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/edit.vue?macro=true";
import { default as indexFI20eXoLxkMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/index.vue?macro=true";
import { default as createvVru6tvduRMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/create.vue?macro=true";
import { default as indexkb9q08CEfmMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/index.vue?macro=true";
import { default as edit7ektvZ1UQWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/[id]/edit.vue?macro=true";
import { default as indexcEIXibweFvMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/[id]/index.vue?macro=true";
import { default as createZo5sN2RPm1Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/create.vue?macro=true";
import { default as index80Hg2z8rP2Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/index.vue?macro=true";
import { default as editgeCzTtnSvZMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/edit.vue?macro=true";
import { default as indexxxioRYl9GuMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/index.vue?macro=true";
import { default as indexGLRAsya0qWMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/index.vue?macro=true";
import { default as editHshr6W1dkCMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/[id]/edit.vue?macro=true";
import { default as indextQRISQ7ywtMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/[id]/index.vue?macro=true";
import { default as _91id_93NhZ9sxf3fzMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/bookings/[id].vue?macro=true";
import { default as indexWuWRFeNH8gMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/bookings/index.vue?macro=true";
import { default as _91id_930H5aFyIPI7Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/earnings/[id].vue?macro=true";
import { default as indexBCXlmWhlaPMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/earnings/index.vue?macro=true";
import { default as edites8ufIPx6eMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/[id]/edit.vue?macro=true";
import { default as indexqUsNTq6DYNMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/[id]/index.vue?macro=true";
import { default as create0Zz0G8pec0Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/create.vue?macro=true";
import { default as indexISzk7U56CCMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/index.vue?macro=true";
import { default as indexFYpBU2VUxtMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/index.vue?macro=true";
import { default as _91id_93PIqyA40gXXMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/payouts/[id].vue?macro=true";
import { default as index7ZRyo8Vw2VMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/payouts/index.vue?macro=true";
import { default as _91id_9383jk5OsdFyMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/receipts/[id].vue?macro=true";
import { default as indexHOOPpV64QIMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/receipts/index.vue?macro=true";
import { default as fleet_45requirediPCk1K3oVfMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/fleet-required.vue?macro=true";
import { default as indexPGNc2EsoZrMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/[id]/index.vue?macro=true";
import { default as editbmQTRXGbtZMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/adjustments/[id]/edit.vue?macro=true";
import { default as indexPRZJjwVFQVMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/adjustments/[id]/index.vue?macro=true";
import { default as createRHJPPBjGaIMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/adjustments/create.vue?macro=true";
import { default as indexq2YYlRJilaMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/adjustments/index.vue?macro=true";
import { default as indexo0hOjr9nXhMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/drivers/index.vue?macro=true";
import { default as editDteVVCILqmMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/edit.vue?macro=true";
import { default as indexg65cBOE0QUMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/index.vue?macro=true";
import { default as editDW3Q0k3ehzMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/[itemId]/edit.vue?macro=true";
import { default as indexkfARqWQafFMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/[itemId]/index.vue?macro=true";
import { default as createaCXKKOKEM8Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/create.vue?macro=true";
import { default as indexDZ1ZKmYxZEMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/index.vue?macro=true";
import { default as createrigcj8amTZMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/create.vue?macro=true";
import { default as indexfyrWDmfkBPMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/index.vue?macro=true";
import { default as indexlAlrOY1Ha1Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/index.vue?macro=true";
import { default as loginh8fMddjfe6Meta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/login.vue?macro=true";
import { default as editGCartPr1ViMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/[id]/edit.vue?macro=true";
import { default as indexAcKfr6BYDFMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/[id]/index.vue?macro=true";
import { default as indexW5glvySWQJMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/categories/[id]/index.vue?macro=true";
import { default as index7uT36MSUwbMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/categories/index.vue?macro=true";
import { default as createsYLZFGjR5sMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/create.vue?macro=true";
import { default as indexWd6uFtocItMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/index.vue?macro=true";
import { default as indexnrmra44dkFMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/profile/index.vue?macro=true";
import { default as editEpz5RLwlaGMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/fleets/edit.vue?macro=true";
import { default as indexGG8XycWEzfMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/index.vue?macro=true";
import { default as editJhiHTDHnLJMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/roles/edit.vue?macro=true";
import { default as indexMQvTx22C0JMeta } from "/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/index.vue?macro=true";
export default [
  {
    name: "agreements-id-drivers",
    path: "/agreements/:id()/drivers",
    meta: driversBzebRKmqnWMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/drivers.vue").then(m => m.default || m)
  },
  {
    name: "agreements-id",
    path: "/agreements/:id()",
    meta: indexGVHGTSwFxvMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "agreements",
    path: "/agreements",
    meta: index1ioKyqHhlRMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/agreements/index.vue").then(m => m.default || m)
  },
  {
    name: "documents-id-edit",
    path: "/documents/:id()/edit",
    meta: editBRTL0IlKNWMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "documents-id",
    path: "/documents/:id()",
    meta: indexFI20eXoLxkMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "documents-create",
    path: "/documents/create",
    meta: createvVru6tvduRMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/create.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexkb9q08CEfmMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "documents-types-id-edit",
    path: "/documents/types/:id()/edit",
    meta: edit7ektvZ1UQWMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "documents-types-id",
    path: "/documents/types/:id()",
    meta: indexcEIXibweFvMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "documents-types-create",
    path: "/documents/types/create",
    meta: createZo5sN2RPm1Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/create.vue").then(m => m.default || m)
  },
  {
    name: "documents-types",
    path: "/documents/types",
    meta: index80Hg2z8rP2Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/types/index.vue").then(m => m.default || m)
  },
  {
    name: "documents-vehicle-changes-id-edit",
    path: "/documents/vehicle-changes/:id()/edit",
    meta: editgeCzTtnSvZMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "documents-vehicle-changes-id",
    path: "/documents/vehicle-changes/:id()",
    meta: indexxxioRYl9GuMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "documents-vehicle-changes",
    path: "/documents/vehicle-changes",
    meta: indexGLRAsya0qWMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/documents/vehicle-changes/index.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-id-edit",
    path: "/driver-partners/:id()/edit",
    meta: editHshr6W1dkCMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-id",
    path: "/driver-partners/:id()",
    meta: indextQRISQ7ywtMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-bookings-id",
    path: "/driver-partners/bookings/:id()",
    meta: _91id_93NhZ9sxf3fzMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/bookings/[id].vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-bookings",
    path: "/driver-partners/bookings",
    meta: indexWuWRFeNH8gMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-earnings-id",
    path: "/driver-partners/earnings/:id()",
    meta: _91id_930H5aFyIPI7Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/earnings/[id].vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-earnings",
    path: "/driver-partners/earnings",
    meta: indexBCXlmWhlaPMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/earnings/index.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-exemptions-id-edit",
    path: "/driver-partners/exemptions/:id()/edit",
    meta: edites8ufIPx6eMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-exemptions-id",
    path: "/driver-partners/exemptions/:id()",
    meta: indexqUsNTq6DYNMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-exemptions-create",
    path: "/driver-partners/exemptions/create",
    meta: create0Zz0G8pec0Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/create.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-exemptions",
    path: "/driver-partners/exemptions",
    meta: indexISzk7U56CCMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/exemptions/index.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners",
    path: "/driver-partners",
    meta: indexFYpBU2VUxtMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/index.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-payouts-id",
    path: "/driver-partners/payouts/:id()",
    meta: _91id_93PIqyA40gXXMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/payouts/[id].vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-payouts",
    path: "/driver-partners/payouts",
    meta: index7ZRyo8Vw2VMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-receipts-id",
    path: "/driver-partners/receipts/:id()",
    meta: _91id_9383jk5OsdFyMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/receipts/[id].vue").then(m => m.default || m)
  },
  {
    name: "driver-partners-receipts",
    path: "/driver-partners/receipts",
    meta: indexHOOPpV64QIMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/driver-partners/receipts/index.vue").then(m => m.default || m)
  },
  {
    name: "fleet-required",
    path: "/fleet-required",
    meta: fleet_45requirediPCk1K3oVfMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/fleet-required.vue").then(m => m.default || m)
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    meta: indexPGNc2EsoZrMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-adjustments-id-edit",
    path: "/invoices/adjustments/:id()/edit",
    meta: editbmQTRXGbtZMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/adjustments/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "invoices-adjustments-id",
    path: "/invoices/adjustments/:id()",
    meta: indexPRZJjwVFQVMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/adjustments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-adjustments-create",
    path: "/invoices/adjustments/create",
    meta: createRHJPPBjGaIMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/adjustments/create.vue").then(m => m.default || m)
  },
  {
    name: "invoices-adjustments",
    path: "/invoices/adjustments",
    meta: indexq2YYlRJilaMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/adjustments/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-billing-profiles-id-drivers",
    path: "/invoices/billing-profiles/:id()/drivers",
    meta: indexo0hOjr9nXhMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/drivers/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-billing-profiles-id-edit",
    path: "/invoices/billing-profiles/:id()/edit",
    meta: editDteVVCILqmMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "invoices-billing-profiles-id",
    path: "/invoices/billing-profiles/:id()",
    meta: indexg65cBOE0QUMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-billing-profiles-id-items-itemId-edit",
    path: "/invoices/billing-profiles/:id()/items/:itemId()/edit",
    meta: editDW3Q0k3ehzMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/[itemId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "invoices-billing-profiles-id-items-itemId",
    path: "/invoices/billing-profiles/:id()/items/:itemId()",
    meta: indexkfARqWQafFMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-billing-profiles-id-items-create",
    path: "/invoices/billing-profiles/:id()/items/create",
    meta: createaCXKKOKEM8Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/create.vue").then(m => m.default || m)
  },
  {
    name: "invoices-billing-profiles-id-items",
    path: "/invoices/billing-profiles/:id()/items",
    meta: indexDZ1ZKmYxZEMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/[id]/items/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-billing-profiles-create",
    path: "/invoices/billing-profiles/create",
    meta: createrigcj8amTZMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/create.vue").then(m => m.default || m)
  },
  {
    name: "invoices-billing-profiles",
    path: "/invoices/billing-profiles",
    meta: indexfyrWDmfkBPMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/billing-profiles/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: indexlAlrOY1Ha1Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginh8fMddjfe6Meta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "news-id-edit",
    path: "/news/:id()/edit",
    meta: editGCartPr1ViMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "news-id",
    path: "/news/:id()",
    meta: indexAcKfr6BYDFMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "news-categories-id",
    path: "/news/categories/:id()",
    meta: indexW5glvySWQJMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/categories/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "news-categories",
    path: "/news/categories",
    meta: index7uT36MSUwbMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "news-create",
    path: "/news/create",
    meta: createsYLZFGjR5sMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/create.vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    meta: indexWd6uFtocItMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexnrmra44dkFMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-fleets-edit",
    path: "/users/:id()/fleets/edit",
    meta: editEpz5RLwlaGMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/fleets/edit.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: indexGG8XycWEzfMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-roles-edit",
    path: "/users/:id()/roles/edit",
    meta: editJhiHTDHnLJMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/[id]/roles/edit.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexMQvTx22C0JMeta || {},
    component: () => import("/home/runner/work/team-portal-frontend/team-portal-frontend/pages/users/index.vue").then(m => m.default || m)
  }
]