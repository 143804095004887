import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiPaginatedResponse, ApiResponse } from '~/api/types/Api'
import type {
  Agreement,
  BalanceBlockExemption,
  BalanceBlockExemptionRequest,
  BillingItem,
  BillingItemRequest,
  BillingProfile,
  BillingProfileRequest,
  BillingProfileSyncDriversRequest,
  Booking,
  DeclineDocumentRequest,
  DeclineVehicleChangeRequest,
  DispatchSystem,
  Document,
  DocumentRequest,
  DocumentType,
  DocumentTypeRequest,
  Driver,
  DriverEarnings,
  DriverRequest,
  Invoice,
  InvoiceAdjustment,
  InvoiceAdjustmentRequest,
  InvoiceItem,
  InvoiceItemRequest,
  ManualBlock,
  ManualBlockRequest,
  NewsCategory,
  NewsPost,
  NewsPostRequest,
  Payment,
  Payout,
  PublishInvoicesRequest,
  VehicleChange,
  VehicleChangeRequest,
} from '~/api/types/DriverPortal'
import type { FetchOptions } from 'ofetch'

export default class DriverPortalService extends ApiServiceBase {
  /*
   * Agreements
   */
  async getAllAgreements(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Agreement[]>> {
    return await this.call('/v1/dpp/agreements', options)
  }

  async getAgreement(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Agreement>> {
    return await this.call(`/v1/dpp/agreements/${id}`, options)
  }

  async getAllDriversForAgreement(
    id: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call(`/v1/dpp/agreements/${id}/drivers`, options)
  }

  /*
   * Balance Block Exemptions
   */
  async getAllBalanceBlockExemptions(
    options?: FetchOptions<'json'>
  ): Promise<ApiPaginatedResponse<BalanceBlockExemption[]>> {
    return await this.call('/v1/dpp/balance-block-exemptions', options)
  }

  async getBalanceBlockExemption(
    id: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call(`/v1/dpp/balance-block-exemptions/${id}`, options)
  }

  async createBalanceBlockExemption(data: BalanceBlockExemptionRequest): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call('/v1/dpp/balance-block-exemptions', {
      method: 'post',
      body: data,
    })
  }

  async updateBalanceBlockExemption(
    id: string | number,
    data: BalanceBlockExemptionRequest
  ): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call(`/v1/dpp/balance-block-exemptions/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteBalanceBlockExemption(id: string | number): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call(`/v1/dpp/balance-block-exemptions/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Billing Items
   */
  async getAllBillingItems(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<BillingItem[]>> {
    return await this.call('/v1/dpp/billing-items', options)
  }

  async getBillingItem(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<BillingItem>> {
    return await this.call(`/v1/dpp/billing-items/${id}`, options)
  }

  async createBillingItem(data: BillingItemRequest): Promise<ApiResponse<BillingItem>> {
    return await this.call('/v1/dpp/billing-items', {
      method: 'post',
      body: data,
    })
  }

  async updateBillingItem(id: string | number, data: BillingItemRequest): Promise<ApiResponse<BillingItem>> {
    return await this.call(`/v1/dpp/billing-items/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteBillingItem(id: string | number): Promise<ApiResponse<BillingItem>> {
    return await this.call(`/v1/dpp/billing-items/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Billing Profiles
   */
  async getAllBillingProfiles(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<BillingProfile[]>> {
    return await this.call('/v1/dpp/billing-profiles', options)
  }

  async getBillingProfile(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/v1/dpp/billing-profiles/${id}`, options)
  }

  async createBillingProfile(data: BillingProfileRequest): Promise<ApiResponse<BillingProfile>> {
    return await this.call('/v1/dpp/billing-profiles', {
      method: 'post',
      body: data,
    })
  }

  async updateBillingProfile(id: string | number, data: BillingProfileRequest): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/v1/dpp/billing-profiles/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteBillingProfile(id: string | number): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/v1/dpp/billing-profiles/${id}`, {
      method: 'delete',
    })
  }

  async syncDriversWithBillingProfile(
    id: string | number,
    data: BillingProfileSyncDriversRequest
  ): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/v1/dpp/billing-profiles/${id}/drivers`, {
      method: 'post',
      body: data,
    })
  }

  async getBillingProfileDrivers(
    id: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call(`/v1/dpp/billing-profiles/${id}/drivers`, options)
  }

  async processBillingProfile(id: string | number): Promise<ApiResponse<void>> {
    return await this.call(`/v1/dpp/billing-profiles/${id}/process`, {
      method: 'post',
    })
  }

  /*
   * Bookings
   */
  async getAllBookings(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Booking[]>> {
    return await this.call('/v1/dpp/bookings', options)
  }

  async getBooking(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Booking>> {
    return await this.call(`/v1/dpp/bookings/${id}`, options)
  }

  /*
   * Dispatch Systems
   */
  async getAllDispatchSystems(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<DispatchSystem[]>> {
    return await this.call('/v1/dpp/dispatch-systems', options)
  }

  /*
   * Documents
   */
  async getAllDocuments(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Document[]>> {
    return await this.call('/v1/dpp/documents', options)
  }

  async createDocument(data: DocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call('/v1/dpp/documents', {
      method: 'post',
      body: useFormData(data),
    })
  }

  async getDocument(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}`, options)
  }

  async updateDocument(id: string | number, data: DocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}`, {
      method: 'post',
      body: useFormData({
        ...data,
        _method: 'put',
      }),
    })
  }

  async deleteDocument(id: string | number): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}`, {
      method: 'delete',
    })
  }

  async approveDocument(id: string | number): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}/approve`, {
      method: 'post',
    })
  }

  async declineDocument(id: string | number, data: DeclineDocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call(`/v1/dpp/documents/${id}/decline`, {
      method: 'post',
      body: data,
    })
  }

  /*
   * Document Types
   */
  async getAllDocumentTypes(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<DocumentType[]>> {
    return await this.call('/v1/dpp/document-types', options)
  }

  async createDocumentType(data: DocumentTypeRequest): Promise<ApiResponse<DocumentType>> {
    return await this.call('/v1/dpp/document-types', {
      method: 'post',
      body: data,
    })
  }

  async getDocumentType(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<DocumentType>> {
    return await this.call(`/v1/dpp/document-types/${id}`, options)
  }

  async updateDocumentType(id: string | number, data: DocumentTypeRequest): Promise<ApiResponse<DocumentType>> {
    return await this.call(`/v1/dpp/document-types/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteDocumentType(id: string | number): Promise<ApiResponse<DocumentType>> {
    return await this.call(`/v1/dpp/document-types/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Drivers
   */
  async getAllDrivers(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call('/v1/dpp/drivers', options)
  }

  async getDriver(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Driver>> {
    return await this.call(`/v1/dpp/drivers/${id}`, options)
  }

  async updateDriver(id: string | number, data: DriverRequest): Promise<ApiResponse<Driver>> {
    return await this.call(`/v1/dpp/drivers/${id}`, {
      method: 'put',
      body: data,
    })
  }

  /*
   * Driver Earnings
   */
  async getAllEarnings(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<DriverEarnings[]>> {
    return await this.call(`/v1/dpp/earnings`, options)
  }

  async getEarnings(driverId: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<DriverEarnings>> {
    return await this.call(`/v1/dpp/earnings/${driverId}`, options)
  }

  /*
   * Invoices
   */
  async getAllInvoices(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Invoice[]>> {
    return await this.call('/v1/dpp/invoices', options)
  }

  async getInvoice(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Invoice>> {
    return await this.call(`/v1/dpp/invoices/${id}`, options)
  }

  async deleteInvoice(id: string | number): Promise<ApiResponse<Invoice>> {
    return await this.call(`/v1/dpp/invoices/${id}`, {
      method: 'delete',
    })
  }

  async publishInvoices(data: PublishInvoicesRequest): Promise<{
    processed: (number | string)[]
    skipped: (number | string)[]
  }> {
    return await this.call(`/v1/dpp/invoices/publish`, {
      method: 'post',
      body: data,
    })
  }

  async publishAllInvoices(): Promise<{
    processed: (number | string)[]
  }> {
    return await this.call(`/v1/dpp/invoices/publish-all`, {
      method: 'post',
    })
  }

  /*
   * Invoice adjustments
   */
  async getAllInvoiceAdjustments(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<InvoiceAdjustment[]>> {
    return await this.call('/v1/dpp/invoice-adjustments', options)
  }

  async getInvoiceAdjustment(
    id: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiResponse<InvoiceAdjustment>> {
    return await this.call(`/v1/dpp/invoice-adjustments/${id}`, options)
  }

  async createInvoiceAdjustment(data: InvoiceAdjustmentRequest): Promise<ApiResponse<InvoiceAdjustment>> {
    return await this.call('/v1/dpp/invoice-adjustments', {
      method: 'post',
      body: data,
    })
  }

  async updateInvoiceAdjustment(
    id: string | number,
    data: InvoiceAdjustmentRequest
  ): Promise<ApiResponse<InvoiceAdjustment>> {
    return await this.call(`/v1/dpp/invoice-adjustments/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteInvoiceAdjustment(id: string | number): Promise<ApiResponse<InvoiceAdjustment>> {
    return await this.call(`/v1/dpp/invoice-adjustments/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Invoice items
   */
  async getInvoiceItem(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<InvoiceItem>> {
    return await this.call(`/v1/dpp/invoice-items/${id}`, options)
  }

  async updateInvoiceItem(id: string | number, data: InvoiceItemRequest): Promise<ApiResponse<InvoiceItem>> {
    return await this.call(`/v1/dpp/invoice-items/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteInvoiceItem(id: string | number): Promise<ApiResponse<InvoiceItem>> {
    return await this.call(`/v1/dpp/invoice-items/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Manual blocks
   */
  async getAllManualBlocks(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<ManualBlock[]>> {
    return await this.call('/v1/dpp/manual-blocks', options)
  }

  async getManualBlock(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<ManualBlock>> {
    return await this.call(`/v1/dpp/manual-blocks/${id}`, options)
  }

  async createManualBlock(data: ManualBlockRequest): Promise<ApiResponse<ManualBlock>> {
    return await this.call('/v1/dpp/manual-blocks', {
      method: 'post',
      body: data,
    })
  }

  async deleteManualBlock(id: string | number): Promise<void> {
    return await this.call(`/v1/dpp/manual-blocks/${id}`, {
      method: 'delete',
    })
  }

  /*
   * News categories
   */
  async getAllNewsCategories(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<NewsCategory[]>> {
    return await this.call('/v1/dpp/news-categories', options)
  }

  async getNewsCategory(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<NewsCategory>> {
    return await this.call(`/v1/dpp/news-categories/${id}`, options)
  }

  /*
   * News posts
   */
  async getAllNewsPosts(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<NewsPost[]>> {
    return await this.call('/v1/dpp/news-posts', options)
  }

  async createNewsPost(data: NewsPostRequest): Promise<ApiResponse<NewsPost>> {
    return await this.call('/v1/dpp/news-posts', {
      method: 'post',
      body: useFormData(data),
    })
  }

  async getNewsPost(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/v1/dpp/news-posts/${id}`, options)
  }

  async updateNewsPost(id: string | number, data: NewsPostRequest): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/v1/dpp/news-posts/${id}`, {
      method: 'post',
      body: useFormData({
        ...data,
        _method: 'put',
      }),
    })
  }

  async deleteNewsPost(id: string | number): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/v1/dpp/news-posts/${id}`, {
      method: 'delete',
    })
  }

  async submitNewsPost(id: string | number): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/v1/dpp/news-posts/${id}/submit`, {
      method: 'post',
    })
  }

  /*
   * Payments
   */
  async getAllPayments(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Payment[]>> {
    return await this.call('/v1/dpp/payments', options)
  }

  async getPayment(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Payment>> {
    return await this.call(`/v1/dpp/payments/${id}`, options)
  }

  async resendSuccessNotification(id: string | number): Promise<void> {
    return await this.call(`/v1/dpp/payments/${id}/resend-success-notification`, {
      method: 'post',
    })
  }

  /*
   * Payouts
   */
  async getAllPayouts(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Payout[]>> {
    return await this.call('/v1/dpp/payouts', options)
  }

  async downloadPayouts(options?: FetchOptions<'blob'>): Promise<Blob> {
    return await this.call('/v1/dpp/payouts', useMerge(options, { query: { download: true } }))
  }

  async getPayout(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Payout>> {
    return await this.call(`/v1/dpp/payouts/${id}`, options)
  }

  /*
   * Vehicle changes
   */
  async getAllVehicleChanges(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<VehicleChange[]>> {
    return await this.call('/v1/dpp/vehicle-changes', options)
  }

  async getVehicleChange(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}`, options)
  }

  async updateVehicleChange(id: string | number, data: VehicleChangeRequest): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async approveVehicleChange(id: string | number): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}/approve`, {
      method: 'post',
    })
  }

  async declineVehicleChange(
    id: string | number,
    data: DeclineVehicleChangeRequest
  ): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/v1/dpp/vehicle-changes/${id}/decline`, {
      method: 'post',
      body: data,
    })
  }
}
