import type { User } from '~/api/types/Authentication'
import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiResponse } from '~/api/types/Api'
import type { FetchOptions } from 'ofetch'

export default class AuthenticationService extends ApiServiceBase {
  async logout(): Promise<any> {
    return await this.call('/v1/logout', {
      method: 'post',
    })
  }

  async user(options?: FetchOptions<'json'>): Promise<ApiResponse<User>> {
    return await this.call('/v1/user', options)
  }
}
