import type { User } from '~/api/types/Authentication'

export default function () {
  const config = useRuntimeConfig()

  const user = useState<User | null>(config.public.api.userStateKey, () => null)

  function can(permission: string): boolean {
    return !!user.value?.permissions?.includes(permission)
  }

  function authorize(permission: string): void {
    if (!can(permission)) {
      throw createError({ statusCode: 403, fatal: true })
    }
  }

  return {
    user,
    can,
    authorize,
  }
}
