import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiPaginatedResponse } from '~/api/types/Api'
import type { Fleet } from '~/api/types/Fleet'
import type { FetchOptions } from 'ofetch'

export default class FleetService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Fleet[]>> {
    return await this.call('/v1/fleets', options)
  }
}
