import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiPaginatedResponse } from '~/api/types/Api'
import type { FetchOptions } from 'ofetch'
import type { Role } from '~/api/types/Roles'

export default class RoleService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Role[]>> {
    return await this.call('/v1/roles', options)
  }
}
